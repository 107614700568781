@use "sass:meta" as ---4bvk1e5e1sb;/**
 * phpMyFAQ 3.2 global layout definitions
 *
 * This Source Code Form is subject to the terms of the Mozilla Public License,
 * v. 2.0. If a copy of the MPL was not distributed with this file, You can
 * obtain one at https://mozilla.org/MPL/2.0/.
 *
 * @package   phpMyFAQ
 * @author    Thorsten Rinne <thorsten@phpmyfaq.de>
 * @copyright 2022-2023 phpMyFAQ Team
 * @license   https://www.mozilla.org/MPL/2.0/ Mozilla Public License Version 2.0
 * @link      https://www.phpmyfaq.de
 * @since     2022-10-03
 */

//
// Global definitions
//

html,
body {
  height: 100%;
}

;@include ---4bvk1e5e1sb.load-css("sass-embedded-legacy-load-done:183");