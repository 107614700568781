@use "sass:meta" as ---4bvk1e5e1sb;.pmf-voting-star {
  background: none;
  border: none;
  font-size: 1.5em;
  padding: 0 0.15em;

  &.selected {
    color: $primary;
  }
}

.pmf-stars-screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

;@include ---4bvk1e5e1sb.load-css("sass-embedded-legacy-load-done:221");