@use "sass:meta" as ---4bvk1e5e1sb;#togglePassword {
  cursor: pointer;

  &:before {
    font-family: 'ForkAwesome';
    content: '\f070';
    font-style: normal;
  }

  &.is-active:before {
    font-family: 'ForkAwesome';
    content: '\f06e';
  }
}

;@include ---4bvk1e5e1sb.load-css("sass-embedded-legacy-load-done:222");